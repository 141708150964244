import { RouteConfig } from "vue-router";
import PostingsComponent from "@/views/postings/postings.vue";
import { Roles } from "../authenticate/authenticate.types";

export const POSTINGS_ROUTES: RouteConfig[] = [
  {
    path: "/postings",
    name: "Postings",
    component: PostingsComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Job Board",
      navIcon: "fa-calendar-alt" 
    }
  }
];