
import { Component, Vue, Prop } from "vue-property-decorator";


import {  
  PracticeEmployee,
} from "./dental-temps.types";

@Component
export default class EmployeeCardComponent extends Vue {
    @Prop() employee!: PracticeEmployee;
    @Prop({default: false}) showRating!: boolean;

    showDetails(employeeId: number): void {
        this.$emit("details", employeeId);
    }

    positionDescription(positions: string[]): string {
        if (!positions || positions.length == 0)
        return "";
        return positions.length == 1 
        ? positions[0]
        : `${positions[0]}, +${positions.length-1}`
    }

    positionTitle(positions: string[]): string {
        if (!positions || positions.length == 0)
            return "";
        return positions.join(", ");
    }
}
