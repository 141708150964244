
import { Component, Vue, Prop } from "vue-property-decorator";
import { EmployeeDetailsModel, EmployeeKey } from "./component.types";
import EmployeeDetailsService from "./component.service";

@Component
export default class EmployeeDetails extends Vue {
  @Prop() employeeKey!: EmployeeKey;
  @Prop({ default: undefined }) officeId!: number | undefined;
  @Prop() isVisible!: boolean;

  model : EmployeeDetailsModel = new EmployeeDetailsModel();

  fields = [
    { key: 'entryDate', label: 'Date', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' },
    { key: 'office', label: 'Office', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' },
    { key: 'rating', label: 'Shift Rating', sortable: false, thClass: 'font-gray', tdClass: 'font-gray rating-col' },
    { key: 'comments', label: 'Comments', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' }
  ];

  isFavorite: boolean | undefined;
  isBusy: boolean = false;

  get showFavorite() {
    return this.officeId != null;
  }

  get initials() {
    if (!this.model)
      return "NN";
    return `${this.model.firstName?.[0]}${this.model.lastName?.[0]}`;
  }

  get favoriteText() {
    return this.model.isFavorite ? "Remove from Favorites" : "Add to Favorites";
  }

  async toggleFavorite() {
    this.isBusy = true;
    var newValue = !this.model.isFavorite;
    var verb = newValue ? "addToFavorites" : "removeFromFavorites";
    await this.$store.dispatch(`Postings/${verb}`, this.employeeKey.userId);
    this.model.isFavorite = newValue;
    this.isBusy = false;
  }

  async showCertificate(certificateId: number) {    
    const blob = await EmployeeDetailsService.getCertificateImage(certificateId);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }
 
  async created() {
    this.isBusy = true;
    this.model = await EmployeeDetailsService.getPracticeRatingsOfDt(this.employeeKey.practiceId, this.employeeKey.userId, this.officeId);
    this.isFavorite = this.model.isFavorite;
    this.isBusy = false;
  }

  closeModal() 
  {
    if (this.isFavorite != this.model.isFavorite)
      this.$emit('isFavoriteChanged', {isFavorite: this.model.isFavorite, rating: this.model.rating});
    this.$emit('update:isVisible', false);
  }
}
