import httpAuth from "@/common/http/http-common";
import { 
  LoginDto, 
  NewPasswordDto, 
  RegisterDto 
} from "./authenticate.types";

class AuthenticateService {
  async login(loginDto: LoginDto): Promise<string> {
    const { data } = await httpAuth().post(
      `Authenticate/Login`,
      loginDto
    );
    return data;
  }

  async storeUserInfo(): Promise<void> {
    const { data } = await httpAuth().get(
      `Authenticate/UserInfo`
    );
    
    sessionStorage.setItem('user-info', JSON.stringify(data));
  }

  async register(registerDto: RegisterDto) {
    const { data } = await httpAuth().post(
      `Authenticate/Register`,
      registerDto
    );
    return data;
  }

  async resetPassword(newPasswordDto: NewPasswordDto) {
    const { data } = await httpAuth().post(
      `Authenticate/ResetPassword`,
      newPasswordDto
    );
    return data;
  }

  async requestPasswordReset(email: string) {
    const { data } = await httpAuth().post(
      `Authenticate/RequestPasswordReset`,
      {email: email}
    );
    return data;
  }

  async showWizard(userId: number) {
    const { data } = await httpAuth().get(
      `Wizard/ShowWizard?userId=${userId}`
    );
    return data;
  }

  async getPracticeOffices(userId: number) {
    const { data } = await httpAuth().get(
      `Postings/GetPracticeOffices?userId=${userId}`
    );
    return data;
  }
}

export default new AuthenticateService();