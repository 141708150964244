
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "./authenticate.mixin";
import { ErrorBag, Field } from "vee-validate";
import { 
  LoginDto,
  Roles
} from "./authenticate.types";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("Authenticate", ["errorMessage"])
  }
})
export default class Login extends Mixins(AuthenticateMixin) {
  loginDto: LoginDto = new LoginDto();
  passwordShow = false;
  loading = false;

  veeFields!: Field;
  veeErrors!: ErrorBag;
  errorMessage!: string;

  viewPassword() {
    this.passwordShow = !this.passwordShow;
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  authenticate(): void {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;

        await this.$store.dispatch("Authenticate/login", this.loginDto);

        if (!this.userInfo()) {
          this.loading = false;
          return;
        }

        const showWizard = await this.$store.dispatch("Authenticate/showWizard", this.userInfo()?.sub);

        this.loading = false;

        if (this.userInfo()?.role === Roles.CustomerSupport) {
          this.$router.push("/customer-support/dashboard");
        } else if (showWizard) {
          this.$router.push("/wizard");
        } else {
          this.$router.push("/postings");
        }
      }
    });
  }
}
