
import { Component, Vue, Prop } from "vue-property-decorator";
import { PracticeUser, PracticeOfficeDropdown } from "./schedulers.types";
import { ErrorBag, Field } from "vee-validate";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("Schedulers", [
      "options", 
      "errorMessage"
    ])
  }
})
export default class SchedulersNewUserComponent extends Vue {
  @Prop() modalShow!: boolean;

  errorMessage!: string;
  options!: PracticeOfficeDropdown[];

  practiceUser: PracticeUser = new PracticeUser();
  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;
  
  validateState(ref: string) {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async addUser() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        await this.$store.dispatch("Schedulers/upsertScheduler", {
          userId: null,
          firstName: this.practiceUser.firstName,
          lastName: this.practiceUser.lastName,
          name: this.practiceUser.name,
          phoneNumber: this.practiceUser.phoneNumber,
          emailAddress: this.practiceUser.emailAddress,
          practiceOfficeSelect: this.options.map(o => {
             practiceOfficeId: o.value,
             status = this.practiceUser.practiceOfficeIds.indexOf(o.value) !== -1 ? "added": "";
          })
        });
        this.loading = false;
        if (!this.errorMessage) {
          this.$emit('reload');
          this.$emit('update:modalShow', false);
        }
      }
    });
  }

  closeModal() {
    this.$emit('update:modalShow', false);
  }
}
