
import { Component, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import OfficeProfilesMixin from "./office-profiles.mixin";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import OfficeNewUser from "./office-new-user.vue";
import ConfirmDialog from "@/views/dialog/confirm-dialog.vue";
import { 
  OfficeProfile,
  OfficeProfileUsers,
  PracticeOfficeUserStatus
 } from "./office-profiles.types";

 @Component({
  components: {
    OfficeNewUser,
    ConfirmDialog
  }
})
export default class OfficeStaffEditComponent extends Mixins(OfficeProfilesMixin, AuthenticateMixin) {
  selectedOfficeEmployee: OfficeProfileUsers = new OfficeProfileUsers();
  officeContact: OfficeProfile = new OfficeProfile();
  officeEmployees: OfficeProfileUsers[] = [];
  loading = false;
  modalShow = false;
  confirmModalShow = false;
  selectedIndex = 0;

  veeFields!: Field;
  veeErrors!: ErrorBag;

  async mounted() {
    const officeUsers = await this.$store.dispatch("OfficeProfiles/fetchOfficeUsers", this.userInfo()?.practiceId);
    this.officeEmployees = [... this.isEdit ? this.selectedOfficeProfile.staff : officeUsers ];
    this.officeEmployees = this.officeEmployees.filter(employee => employee.userId !== this.userInfo()?.sub);
    this.officeContact = { ...this.selectedOfficeProfile };
    this.selectedOfficeEmployee = this.officeEmployees[0] ?? "";
    this.$validator.reset();
  }

  addNewUser() {
    this.$store.commit("OfficeProfiles/updateField", {
      path: "errorMessage",
      value: null
    });
    this.modalShow = true;
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async onChange(officeProfileUsers: OfficeProfileUsers) {
    this.loading = true;
    await this.$store.dispatch("OfficeProfiles/upsertUserToPracticeOffice", {
      userId: officeProfileUsers.userId,
      practiceOfficeId: this.selectedOfficeProfile.practiceOfficeId,
      status: officeProfileUsers.inOffice ? 
        PracticeOfficeUserStatus.Active : 
        PracticeOfficeUserStatus.Inactive
    });
    this.loading = false;
  }

  reload(staff: OfficeProfileUsers) {
    if (this.isEdit) {
      this.selectedOfficeProfile.staff.push(staff);
      this.$store.commit("OfficeProfiles/updateField", {
        path: "selectedOfficeProfile",
        value: this.selectedOfficeProfile
      }); 
      this.officeEmployees = [ ...this.selectedOfficeProfile.staff ];
    } else {
      this.officeEmployees.push(staff);
    }
  }

  style() {
    return this.isEdit ? { height: "425px" } : { height: "380px" };
  }

  async back() {
    this.$emit('update:activeTab', 1);
  }

  tryNext() {
    if (this.officeEmployees.some(x => x.inOffice))
      this.next();
    else
      this.confirmModalShow = true;
  }

  next() {
    this.selectedOfficeProfile.staff = [ ...this.officeEmployees ];
    this.$emit('update:activeTab', 3);
  }
}
