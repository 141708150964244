import { render, staticRenderFns } from "./office-information-edit.vue?vue&type=template&id=6ddb2a30&scoped=true&lang=pug"
import script from "./office-information-edit.vue?vue&type=script&lang=ts"
export * from "./office-information-edit.vue?vue&type=script&lang=ts"
import style0 from "./office-information-edit.vue?vue&type=style&index=0&id=6ddb2a30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ddb2a30",
  null
  
)

export default component.exports