import httpAuth from "@/common/http/http-common";


class EmployeeDetailsService {
  async getPracticeRatingsOfDt(practiceId: number, userId: number, practiceOfficeId?: number) {
    var url = `Ratings/GetUserPracticeDetails?practiceId=${practiceId}&userId=${userId}`;
    if (practiceOfficeId)
      url += `&practiceOfficeId=${practiceOfficeId}`;
    const { data } = await httpAuth().get(url);
    return data;
  }

  async getCertificateImage(certificateId: number) {
    var url = `Employees/Certificate?certificateId=${certificateId}`;
    const { data } = await httpAuth().get(url, {
      responseType: 'blob',
      timeout: 30000,
    });
    return data;
  }
}

export default new EmployeeDetailsService();