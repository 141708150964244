
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

@Component
export default class Header extends Mixins(AuthenticateMixin) {
  get initials(): string {
    if (this.userInfo() && this.userInfo()?.firstName && this.userInfo()?.lastName) {
      return this.userInfo()?.firstName[0] ?? "" + this.userInfo()?.lastName[0] ?? "";
    }
    
    return "";
  }

  get showBackToDashboard(): boolean {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    return Boolean(supportUserInfo) && Boolean(supportToken);
  }

  logoutPush(): void {
    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.removeItem("user-info");
    sessionStorage.removeItem("token");
    this.$store.commit("reset");
    this.$router.push("/login");
  }

  backToDashboard(): void {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    if (!supportUserInfo || !supportToken){
      return;
    }

    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.setItem("user-info", supportUserInfo);
    sessionStorage.setItem("token", supportToken);

    window.location.replace("/customer-support/dashboard");
  }
}
