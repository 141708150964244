import httpAuth from "@/common/http/http-common";

class DentalTempsService {
  async getPracticeEmployees(practiceOfficeId: number) {
    const { data } = await httpAuth().get(
      `Employees/GetPracticeEmployees?practiceOfficeId=${practiceOfficeId}`
    );
    return data;
  }

  async getAllEmployees() {
    const { data } = await httpAuth().get(
      `Employees/GetEmployees`
    );
    return data;
  }
}

export default new DentalTempsService();