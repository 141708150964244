import httpAuth from "@/common/http/http-common";
import { 
  UpsertPracticeOffice,
  UpsertPracticeOfficeDefaultRate,
  UpsertPracticeOfficeHours,
  PracticeOfficeUserStatusDto,
  PracticeOfficeStatusDto,
  AddNewUserDto,
  PracticeDetails,
  UpsertPracticeOfficeRatesRequest,
  OfficePayrollForm
} from "./office-profiles.types";

class OfficeProfilesService {
  async getOfficeProfiles(practiceId: number) {
    const { data } = await httpAuth().get(
      `OfficeProfiles/GetOfficeProfiles?practiceId=${practiceId}`
    );
    return data;
  }

  async getPracticeDetails(practiceId: number) {
    const { data } = await httpAuth().get(
      `OfficeProfiles/getPracticeDetails?practiceId=${practiceId}`
    );
    return data;
  }

  async getOfficeUsers(practiceId: number) {
    const { data } = await httpAuth().get(
      `OfficeProfiles/GetOfficeUsers?practiceId=${practiceId}`
    );
    return data;
  }

  async upsertPracticeOffice(upsertPracticeOffice: UpsertPracticeOffice) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/UpsertPracticeOffice`,
      upsertPracticeOffice
    );
    return data;
  }

  async upsertPracticeOfficeDefaultRate(upsertPracticeOfficeDefaultRate: UpsertPracticeOfficeDefaultRate) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/UpsertPracticeOfficeDefaultRate`,
      upsertPracticeOfficeDefaultRate
    );
    return data;
  }

  async UpsertPracticeOfficeRates(request: UpsertPracticeOfficeRatesRequest) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/UpsertPracticeOfficeRates`,
      request
    );
    return data;
  }

  async savePayroll(model: OfficePayrollForm) {
    const { data } = await httpAuth().put(
      'OfficeProfiles/SavePayroll',
      model
    );
    return data;
  }

  async upsertPracticeOfficeHours(upsertPracticeOfficeHours: UpsertPracticeOfficeHours) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/UpsertPracticeOfficeHours`,
      upsertPracticeOfficeHours
    );
    return data;
  }

  async setPracticeOfficeStatus(practiceOfficeStatusDto: PracticeOfficeStatusDto) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/SetPracticeOfficeStatus`,
      practiceOfficeStatusDto
    );
    return data;
  }

  async upsertUserToPracticeOffice(practiceOfficeUserStatusDto: PracticeOfficeUserStatusDto) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/UpsertUserToPracticeOffice`,
      practiceOfficeUserStatusDto
    );
    return data;
  }

  async addNewUser(addNewUserDto: AddNewUserDto) {
    const { data } = await httpAuth().post(
      `OfficeProfiles/AddNewUser`,
      addNewUserDto
    );
    return data;
  }

  async getPositions() {
    const { data } = await httpAuth().get(
      `Postings/GetPositions`
    );
    return data;
  }

  async updatePractice(practiceDetails: PracticeDetails) {
    const { data } = await httpAuth().put(
      `OfficeProfiles/UpdatePractice`,
      practiceDetails
    );
    return data;
  }
}

export default new OfficeProfilesService();