import httpAuth from "@/common/http/http-common";
import { 
  NotificationStatusDto,
  IPracticeOfficeNotificationState
} from "./notifications.types";

class NotificationsService {
  async getOfficeNotifications(userId: number) {
    const { data } = await httpAuth().get(
      `Notifications/GetPracticeOfficeNotificationsByUser?userId=${userId}`
    );
    return data;
  }

  async getOfficeNotificationState(userId: number, fromId: number): Promise<IPracticeOfficeNotificationState> {
    const { data } = await httpAuth().get(
      `Notifications/GetPracticeOfficeNotificationStateByUser?userId=${userId}&fromId=${fromId}`
    );
    return data;
  }

  async setPracticeOfficeNotificationStatus(notificationStatusDto: NotificationStatusDto) {
    const { data } = await httpAuth().post(
      `Notifications/SetPracticeOfficeNotificationStatus`,
      notificationStatusDto
    );
    return data;
  }
}

export default new NotificationsService();